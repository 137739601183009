import {
  InputContainer,
  InputError,
  InputHelperText,
} from 'components/Input/InputPartials';
import TextInput from 'components/Input/TextInput';
import { DOBInputProps } from 'components/Input/helpers';
import { Title } from 'components/Typography';
import { parse } from 'date-fns';
import { getLocaleFromPath } from 'lib/locale/locale';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

const DOBFieldsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const DOBFieldset = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;
`;
const DOBLegend = styled(Title)`
  border: none;
  margin: 0;
  padding: 0;
`;

const DOBField = styled.div`
  max-width: 75px;
  label {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`;
const DOBYearField = styled(DOBField)`
  max-width: 100px;
`;

const DOBInput: FC<DOBInputProps> = ({
  onChange,
  label,
  helperText,
  error,
  showError = true,
  value,
}) => {
  const { asPath } = useRouter();
  const isUSLocale = getLocaleFromPath(asPath, 'en-us') === 'en-us';

  const valueAsDate = value && parse(value, 'yyyy-MM-dd', new Date());
  const [day, setDay] = useState<string>(
    `${valueAsDate ? valueAsDate.getDate() : ''}`
  );
  const [month, setMonth] = useState<string>(
    `${valueAsDate ? valueAsDate.getMonth() + 1 : ''}`
  );
  const [year, setYear] = useState<string>(
    `${valueAsDate ? valueAsDate.getFullYear() : ''}`
  );

  useEffect(() => {
    if (day.length > 0 && month.length > 0 && year.length >= 4) {
      onChange(`${year}-${month}-${day}`);
    } else {
      onChange('');
    }
  }, [day, month, year]);

  return (
    <InputContainer>
      <DOBFieldset>
        <DOBLegend as="legend" variant="1" size="XS">
          {label}
        </DOBLegend>

        <DOBFieldsContainer>
          {isUSLocale ? (
            <>
              <DOBField>
                <TextInput
                  value={month}
                  onChange={month => setMonth(month)}
                  name="month"
                  border="outline"
                  label="Month"
                  placeholder="MM"
                  inputMode="numeric"
                />
              </DOBField>
              <DOBField>
                <TextInput
                  value={day}
                  onChange={day => setDay(day)}
                  name="day"
                  border="outline"
                  label="Day"
                  placeholder="DD"
                  inputMode="numeric"
                />
              </DOBField>
            </>
          ) : (
            <>
              <DOBField>
                <TextInput
                  value={day}
                  onChange={day => setDay(day)}
                  name="day"
                  border="outline"
                  label="Day"
                  placeholder="DD"
                  inputMode="numeric"
                />
              </DOBField>
              <DOBField>
                <TextInput
                  value={month}
                  onChange={month => setMonth(month)}
                  name="month"
                  border="outline"
                  label="Month"
                  placeholder="MM"
                  inputMode="numeric"
                />
              </DOBField>
            </>
          )}

          <DOBYearField>
            <TextInput
              value={year}
              onChange={year => setYear(year)}
              name="year"
              border="outline"
              label="Year"
              placeholder="YYYY"
              inputMode="numeric"
            />
          </DOBYearField>
        </DOBFieldsContainer>
      </DOBFieldset>

      <InputHelperText helperText={helperText} />
      <InputError
        error={error}
        visible={showError}
        hasHelperText={Boolean(helperText)}
      />
    </InputContainer>
  );
};

export default DOBInput;
