import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { ISubscribePage } from 'generated/contentful';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import { ContentModeller } from 'models/contentful/shared/types';
import {
  SubscribeModel,
  subscribeModel,
} from 'models/contentful/subscribe/types';

const toSubscribe: ContentModeller<ISubscribePage, SubscribeModel> = ({
  content,
}) => {
  const model: SubscribeModel = {
    welcomeText: content.fields.welcomeText,
    sidePic: { url: content.fields.sidePic.fields.file.url },
    signupText: content.fields.signupText,
    blockType: 'Subscribe',
  };

  return pipe(
    subscribeModel.decode(model),
    fold(
      reportTypeErrors({
        model: 'Subscribe',
        id: content.sys.id,
        fallback: null,
      }),
      returnValidModel
    )
  );
};

export default toSubscribe;
