import fetch from 'cross-fetch';
import type { ContentTypeId, ContentTypes } from 'lib/contentful/fetchResource';
import { removeUndefinedTopLevelKeys } from 'lib/util';
import qs from 'qs';
import { useCallback, useEffect, useState } from 'react';

interface UseContentResourceArgs<T extends ContentTypes, U> {
  locale?: string;
  slug?: string;
  limit?: number;
  type: ContentTypeId;
  mapper?: (args: { content: T; locale: string }) => U | null;
}

const useContentResource = <T extends ContentTypes, U>({
  locale,
  slug,
  limit = 1,
  type,
  mapper,
}: UseContentResourceArgs<T, U>): {
  data: U[];
  error: Error | null;
  loading: boolean;
} => {
  const [data, setData] = useState<U[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const hash = `${slug},${locale},${limit},${type}`;

  const fetchResource = useCallback(async () => {
    setData([]);
    setLoading(true);
    setError(null);

    try {
      const query = qs.stringify(
        removeUndefinedTopLevelKeys({
          type,
          limit,
          slug,
          locale,
        })
      );

      const resource = await fetch(`/services/content/fetch-resource?${query}`);

      const unrefinedContent = (await resource.json()) as T[];

      const content = (
        mapper
          ? unrefinedContent.map(item =>
              mapper({ content: item, locale: `${locale}` })
            )
          : unrefinedContent
      ) as (U | null)[];

      setData(content.filter((c): c is U => c !== null));

      setLoading(false);
    } catch (e) {
      setLoading(false);

      setError(e as Error);
    }
  }, [hash]);

  useEffect(() => {
    fetchResource();
  }, [hash]);

  return { data, loading, error };
};

export default useContentResource;
