import * as t from 'io-ts';
import { imageModel } from 'models/contentful/shared/types';

export const subscribeModel = t.exact(
  t.interface({
    welcomeText: t.string,
    signupText: t.string,
    sidePic: t.exact(imageModel),
    blockType: t.literal('Subscribe'),
  }),
  'Subscribe'
);

export type SubscribeModel = t.TypeOf<typeof subscribeModel>;
