import { createSelector } from '@reduxjs/toolkit';
import { BreakpointKey } from 'lib/toResponsiveValue';
import { selectBreakpoint } from 'store/dimensions/selectors';
import type {
  OverlayName,
  OverlayPosition,
  OverlaysState,
} from 'store/overlays/types';
import { State } from 'store/types';

const breakpointPositions: Record<BreakpointKey, OverlayPosition> = {
  xs: 'bottomSheet',
  s: 'bottomSheet',
  m: 'bottomSheet',
  l: 'modal',
  xl: 'modal',
  xxl: 'modal',
};

export const selectOverlays = (state: State): OverlaysState => state.overlays;

export const selectIsOverlayOpen = createSelector(
  selectOverlays,
  (overlays: OverlaysState): boolean => overlays.open === true
);

export const selectOverlayName = createSelector(
  selectOverlays,
  (overlays: OverlaysState): OverlayName => overlays.name
);

export const selectShouldCloseOnOutsideClick = createSelector(
  selectOverlays,
  (overlays: OverlaysState): boolean => overlays.closeOnOutsideClick
);

export const selectOverlayProps = createSelector(
  selectOverlays,
  (overlays: OverlaysState): Record<string, unknown> => overlays.props
);

export const selectOverlayPosition = createSelector(
  selectBreakpoint,
  (breakpoint?: BreakpointKey): OverlayPosition => {
    if (breakpoint) {
      return breakpointPositions[breakpoint];
    }

    return breakpointPositions.xs;
  }
);
