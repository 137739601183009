import { createSelector } from '@reduxjs/toolkit';
import type {
  DimensionsBreakpoint,
  DimensionsState,
} from 'store/dimensions/types';
import type { State } from 'store/types';
import { numericBreakpoints } from 'theme/breakpoints';

const ALL_BREAKPOINTS = {
  xs: 0,
  s: numericBreakpoints.s,
  m: numericBreakpoints.m,
  l: numericBreakpoints.l,
  xl: numericBreakpoints.xl,
  xxl: numericBreakpoints.xxl,
};

export const selectDimensions = (state: State): DimensionsState =>
  state.dimensions;

export const selectBreakpointToCompare = (
  _: State,
  { breakpointToCompare }: { breakpointToCompare: DimensionsBreakpoint }
): DimensionsBreakpoint => breakpointToCompare;

export const selectBreakpoint = createSelector(
  selectDimensions,
  (dimensions: DimensionsState): DimensionsState['breakpoint'] =>
    dimensions.breakpoint
);

export const selectIsBreakpointGreaterThan = createSelector(
  selectBreakpoint,
  selectBreakpointToCompare,
  (
    breakpoint: DimensionsBreakpoint | undefined,
    breakpointToCompare: DimensionsBreakpoint
  ): boolean => {
    if (breakpoint === undefined) {
      return false;
    }

    return ALL_BREAKPOINTS[breakpoint] > ALL_BREAKPOINTS[breakpointToCompare];
  }
);

export const selectIsBreakpointLessThan = createSelector(
  selectBreakpoint,
  selectBreakpointToCompare,
  (
    breakpoint: DimensionsBreakpoint | undefined,
    breakpointToCompare: DimensionsBreakpoint
  ): boolean => {
    if (breakpoint === undefined) {
      return false;
    }

    return ALL_BREAKPOINTS[breakpoint] < ALL_BREAKPOINTS[breakpointToCompare];
  }
);

export const selectIsMobile = createSelector(
  selectBreakpoint,
  (breakpoint: DimensionsBreakpoint | undefined): boolean => {
    if (breakpoint === undefined) {
      return false;
    }

    return ALL_BREAKPOINTS[breakpoint] < ALL_BREAKPOINTS.l;
  }
);
