import FormButton, { SpinnerWithText } from 'components/FormButton';
import Gap from 'components/Gap';
import { Column, Grid } from 'components/Grid';
import { CloseButton } from 'components/HeaderButtons';
import { CheckboxInput, EmailInput, Select, TextInput } from 'components/Input';
import CheckboxGroupInput from 'components/Input/CheckboxGroupInput';
import DOBInput from 'components/Input/DOBInput';
import PhoneInput from 'components/PhoneInput';
import { Longform, Title } from 'components/Typography';
import { differenceInYears, parse } from 'date-fns';
import { FormApi } from 'final-form';
import { countryList, getCountryFromPath } from 'lib/locale';
import { isEmail } from 'lib/validation';
import { CountryCode } from 'libphonenumber-js';
import RouterLink from 'next/link';
import { useRouter } from 'next/router';
import { FC, ReactNode, useCallback, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { OMETRIA_DATE_FORMAT } from 'store/campaigns/api';
import { joinCampaign } from 'store/campaigns/thunks';
import { CampaignFormValues } from 'store/campaigns/types';
import { useLazyDispatch, useSelector } from 'store/hooks';
import { closeOverlay } from 'store/overlays';
import {
  selectOverlayPosition,
  selectShouldCloseOnOutsideClick,
} from 'store/overlays/selectors';
import { Dispatch } from 'store/types';
import styled, { css } from 'styled-components';

export interface CampaignFormProps {
  initialValues?: Partial<CampaignFormValues>;
  image?: string;
  inline?: boolean;
  onSubmit: (
    values: CampaignFormValues,
    form: FormApi<CampaignFormValues, Partial<CampaignFormValues>>
  ) => void;
  showNameFields?: boolean;
  showExtendedFields?: boolean;
  title?: string;
  children?: ReactNode;
}

export const FormColumn = styled(Column)<{ inline?: boolean }>(
  ({ inline, theme: { bp, colors } }) => css`
    background-color: ${colors.white};
    position: relative;

    overflow-y: ${inline ? 'hidden' : 'auto'};
    overflow-x: hidden;
    height: ${inline ? 'auto' : '80vh'};

    ${bp.l} {
      padding: ${inline ? '0' : '24px'};
    }
  `
);

export const SidePictureColumn = styled(Column)<{ backgroundImage?: string }>(
  ({ backgroundImage, theme: { bp, colors } }) => css`
    display: none;
    position: relative;
    margin-left: -24px;
    background: ${colors.white +
    (backgroundImage ? ` url(${backgroundImage}) no-repeat 50% 50%` : '')};
    background-size: cover;

    ${bp.l} {
      display: block;
    }
  `
);

export const DesktopCloseButton = styled(CloseButton)(
  ({ theme: { bp } }) => css`
    display: none;
    position: absolute;
    z-index: 1;
    top: 24px;
    right: 24px;

    ${bp.l} {
      display: flex;
    }
  `
);

export const PictureSizer = styled.div`
  height: 0;
  width: 100%;
`;

const CloseOnOutsideClick = styled.div<{ displayed: boolean }>(
  ({ displayed }) => css`
    display: ${displayed ? 'block' : 'none'};
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `
);

export const getPmFormAttribute = (
  providedPmForm: string | undefined,
  path: string,
  inline?: boolean
): string => {
  if (providedPmForm) {
    return providedPmForm;
  }

  if (!inline) {
    return 'newsletter_overlay';
  }

  const storelessPath = path.replace(/\/en\-[a-zA-Z]{2}\/?/, '');

  return storelessPath.replace(/[-]/g, '_');
};

export const parseCampaignCheckboxValue = (value: boolean): '1' | '0' =>
  value === true ? '1' : '0';

export const formatCampaignCheckboxValue = (value: string): boolean => {
  return value === '1' ? true : false;
};

type ErrorsOf<T> = {
  [P in keyof T]?: string;
};

export const validateCampaignForm = (
  values: Partial<CampaignFormValues>,
  extended: boolean
): ErrorsOf<CampaignFormValues> => {
  const errors: ErrorsOf<CampaignFormValues> = {};

  if (!isEmail(values.ue)) {
    errors.ue = 'Please enter a valid email address';
  }

  if (extended && values.date_of_birth) {
    const parsedDate = parse(
      values.date_of_birth,
      OMETRIA_DATE_FORMAT,
      new Date()
    );
    if (parsedDate.toString() === 'Invalid Date') {
      errors.date_of_birth = 'Please enter a valid date of birth';
    } else {
      if (parsedDate > new Date()) {
        errors.date_of_birth = 'Please enter a valid date of birth';
      } else {
        const yearsFromNow = differenceInYears(new Date(), parsedDate);
        if (yearsFromNow > 100) {
          errors.date_of_birth = 'Please enter a valid date of birth';
        }
      }
    }
  }

  if (extended && !values.gdpr) {
    errors.gdpr = 'Please accept our Terms and Conditions and Privacy Policy';
  }

  return errors;
};

export const defaultOnSubmitCampaignForm =
  (dispatch: Dispatch): CampaignFormProps['onSubmit'] =>
  async (values, form) => {
    const res = await dispatch(joinCampaign(values));

    if (res.meta.requestStatus === 'fulfilled') {
      form.restart();
    }
  };

const CampaignForm: FC<CampaignFormProps> = ({
  children,
  image,
  initialValues = {},
  inline = false,
  onSubmit,
  showNameFields = false,
  showExtendedFields = false,
  title = 'Join the List',
}) => {
  const { asPath } = useRouter();

  const lazyDispatch = useLazyDispatch();

  const close = useCallback(lazyDispatch(closeOverlay()), [closeOverlay]);

  const pmForm = getPmFormAttribute(initialValues.pm_form, asPath, inline);

  const overlayPosition = useSelector(selectOverlayPosition);
  const closeOnOutsideClick = useSelector(selectShouldCloseOnOutsideClick);
  const submitting = useSelector(state => state.campaigns.inProgress[pmForm]);

  const availablePreferences = [
    { value: 'women', label: 'Women' },
    { value: 'men', label: 'Men' },
    { value: 'kids', label: 'Kids' },
  ];

  const initialFormValues: CampaignFormValues = useMemo(
    () => ({
      ue: '',
      firstname: '',
      lastname: '',
      date_of_birth: '',
      phone_number: '',
      preferences: [],
      country_id: getCountryFromPath(asPath, 'us'),
      marketing_optin: 'EXPLICITLY_OPTEDIN',
      gdpr: false,
      ...initialValues,
      pm_form: pmForm,
    }),
    [asPath, initialValues, inline]
  );

  return (
    <Grid columns={{ xs: 6, l: 12 }}>
      <CloseOnOutsideClick
        displayed={
          !inline && overlayPosition === 'modal' && closeOnOutsideClick
        }
        onClick={close}
      />
      <FormColumn
        inline={inline}
        starts={{
          xs: 1,
          l: inline ? 4 : 2,
          xl: inline ? 5 : 3,
          xxl: inline ? 5 : 4,
        }}
        spans={{
          xs: 6,
          l: inline ? 6 : 5,
          xl: 4,
          xxl: inline ? 4 : 3,
        }}
      >
        <Form<CampaignFormValues>
          initialValues={initialFormValues}
          onSubmit={onSubmit}
          validate={values => validateCampaignForm(values, showExtendedFields)}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              {inline ? null : (
                <>
                  <Title variant="2" size="S">
                    {title}
                  </Title>
                  <Gap size={{ xs: 16, xl: 32 }} />
                </>
              )}

              <Field<string> name="ue">
                {({ input, meta }) => (
                  <EmailInput
                    error={meta.error}
                    label="Email (required)"
                    name={input.name}
                    onChange={input.onChange}
                    showError={meta.touched}
                    value={input.value}
                    border="outline"
                    placeholder="Enter your email"
                    required={true}
                  />
                )}
              </Field>
              <Gap size={32} />
              <Field<string> name="phone_number">
                {({ input, meta }) => (
                  <PhoneInput
                    name="phone_number"
                    defaultCountryCode={
                      getCountryFromPath(
                        asPath,
                        'us'
                      ).toUpperCase() as CountryCode
                    }
                    altStyle
                    error={meta.error}
                    label="Phone Number"
                    onChange={input.onChange}
                    showError={meta.touched}
                    value={input.value}
                  />
                )}
              </Field>
              {showNameFields ? (
                <>
                  <Gap size={32} />
                  <Field<string> name="firstname">
                    {({ input, meta }) => (
                      <TextInput
                        error={meta.error}
                        label="First Name"
                        name={input.name}
                        onChange={input.onChange}
                        showError={meta.touched}
                        value={input.value}
                        border="outline"
                        placeholder="Enter your first name"
                      />
                    )}
                  </Field>
                  <Gap size={32} />
                  <Field<string> name="lastname">
                    {({ input, meta }) => (
                      <TextInput
                        error={meta.error}
                        label="Last Name"
                        name={input.name}
                        onChange={input.onChange}
                        showError={meta.touched}
                        value={input.value}
                        border="outline"
                        placeholder="Enter your last name"
                      />
                    )}
                  </Field>
                </>
              ) : null}

              {showExtendedFields ? (
                <>
                  <Gap size={32} />
                  <Field<string> name="country_id">
                    {({ input }) => (
                      <Select
                        options={countryList.map(({ code, name }) => ({
                          value: code,
                          label: name,
                        }))}
                        label="Country"
                        name="country_id"
                        value={input.value}
                        onChange={input.onChange}
                        border="outline"
                      />
                    )}
                  </Field>
                  <Gap size={32} />
                  <Field<string> name="date_of_birth">
                    {({ input, meta }) => (
                      <DOBInput
                        name={input.name}
                        value={input.value}
                        error={meta.error}
                        showError={meta.touched}
                        onChange={input.onChange}
                        label="Date of Birth"
                      />
                    )}
                  </Field>
                  <Gap size={32} />
                  <Field<string[]> name="preferences">
                    {({ input, meta }) => (
                      <CheckboxGroupInput
                        name={input.name}
                        value={input.value}
                        options={availablePreferences}
                        error={meta.error}
                        showError={meta.touched}
                        onChange={input.onChange}
                        label="Communication Preferences"
                      />
                    )}
                  </Field>
                  <Gap size={32} />
                  <Field<string> name="gdpr">
                    {({ input, meta }) => (
                      <CheckboxInput
                        name={input.name}
                        value={input.value}
                        error={meta.error}
                        showError={meta.touched}
                        onChange={input.onChange}
                        label="Communication Preferences"
                      >
                        <Longform variant="1" size="XS">
                          Subscribe to receive email & SMS updates about Perfect
                          Moment products, services and events. When you
                          subscribe, you confirm you have read{' '}
                          <RouterLink
                            legacyBehavior={true}
                            href="/privacy-policy"
                            passHref={true}
                          >
                            <a target="_blank">
                              Perfect Moment’s Privacy Policy.
                            </a>
                          </RouterLink>
                          .
                        </Longform>
                      </CheckboxInput>
                    )}
                  </Field>
                </>
              ) : null}

              {children ? (
                <>
                  <Gap size={32} />
                  {children}
                </>
              ) : null}
              <Gap size={32} />
              <FormButton disabled={submitting}>
                {({ color }) =>
                  submitting ? (
                    <SpinnerWithText color={color}>
                      Subscribing...
                    </SpinnerWithText>
                  ) : (
                    'Subscribe'
                  )
                }
              </FormButton>
              <Gap size={16} />
            </form>
          )}
        </Form>
      </FormColumn>
      {inline ? null : (
        <SidePictureColumn
          starts={{ xs: 7, xl: 7 }}
          spans={{ xs: 5, xl: 4, xxl: 3 }}
          backgroundImage={image}
        >
          <PictureSizer />
          <DesktopCloseButton color="white" onClick={close} />
        </SidePictureColumn>
      )}
    </Grid>
  );
};

export default CampaignForm;
