import CampaignForm from 'components/CampaignForm';
import {
  CampaignFormProps,
  defaultOnSubmitCampaignForm,
} from 'components/CampaignForm/CampaignForm';
import { ISubscribePage } from 'generated/contentful';
import useContentResource from 'hooks/useContentResource';
import toSubscribe from 'models/contentful/subscribe/serializers';
import { SubscribeModel } from 'models/contentful/subscribe/types';
import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'store/hooks';

interface NewsletterFormProps extends Pick<CampaignFormProps, 'inline'> {
  email?: string;
}

const NewsletterForm: FC<NewsletterFormProps> = ({ email, inline }) => {
  const dispatch = useDispatch();

  const ue = useSelector(state => state.customer.email);

  const subscribePage = useContentResource<ISubscribePage, SubscribeModel>({
    type: 'subscribePage',
    mapper: toSubscribe,
  });

  const onSubmit = useCallback(defaultOnSubmitCampaignForm(dispatch), [
    dispatch,
  ]);

  return (
    <CampaignForm
      initialValues={{ ue: email ?? ue }}
      inline={inline}
      image={subscribePage.data[0]?.sidePic.url}
      onSubmit={onSubmit}
      showNameFields={true}
      showExtendedFields={true}
    />
  );
};

export default NewsletterForm;
