import Gap from 'components/Gap';
import CheckboxInput from 'components/Input/CheckboxInput';
import type { BooleanGroupInputProps } from 'components/Input/helpers';
import {
  InputContainer,
  InputError,
  InputHelperText,
  InputLabel,
} from 'components/Input/InputPartials';
import { ChangeEventHandler, FC, Fragment, useCallback } from 'react';

interface CheckboxGroupInputProps
  extends Omit<BooleanGroupInputProps, 'value' | 'onChange'> {
  value: string[];
  onChange: (values: string[]) => void;
}

export const toggleValueOnChangeCallback =
  (
    onChange: CheckboxGroupInputProps['onChange'],
    value: CheckboxGroupInputProps['value']
  ): ChangeEventHandler<HTMLInputElement> =>
  e => {
    const checkboxValue = e.target.value;
    if (value.includes(checkboxValue)) {
      onChange(value.filter(v => v !== checkboxValue));
    } else {
      onChange(value.concat(checkboxValue));
    }
  };

const CheckboxGroupInput: FC<CheckboxGroupInputProps> = ({
  label,
  disabled,
  helperText,
  error,
  options,
  name,
  value,
  showError = true,
  onChange,
}) => {
  const toggleValueOnChange = useCallback(
    toggleValueOnChangeCallback(onChange, value),
    [onChange, value]
  );

  return (
    <InputContainer>
      <InputLabel label={label} disabled={disabled} />
      <Gap size={8} />
      {options.map((option, index, array) => (
        <Fragment key={option.value + option.label}>
          <CheckboxInput
            name={name}
            value={option.value}
            disabled={disabled}
            checked={value.includes(option.value)}
            label={option.label}
            icon={option.icon}
            onChange={toggleValueOnChange}
          />
          <Gap size={index === array.length - 1 ? 0 : 8} />
        </Fragment>
      ))}
      <InputHelperText helperText={helperText} />
      <InputError
        error={error}
        visible={showError}
        hasHelperText={Boolean(helperText)}
      />
    </InputContainer>
  );
};

export default CheckboxGroupInput;
